import laApiInstance from '@/util/laApi';
const laApi = laApiInstance();

const getDefaultState = () => ({
  alerts: null,
  ongoingAlerts: null,
  plannedAlerts: null,
});

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setAlertLog(state, alerts) {
      state.alerts = alerts;
    },
    setOngoingAlerts(state, alerts) {
      state.ongoingAlerts = alerts;
    },
    setPlannedAlerts(state, alerts) {
      state.plannedAlerts = alerts;
    },
  },
  actions: {
    getAlertLog({ commit }) {
      return laApi.get('alerts/').then((response) => commit('setAlertLog', response.data));
    },
    getOngoingAlerts({ commit }) {
      return laApi
        .get('alerts/ongoing')
        .then((response) => commit('setOngoingAlerts', response.data));
    },
    getPlannedAlerts({ commit }) {
      return laApi
        .get('alerts/planned')
        .then((response) => commit('setPlannedAlerts', response.data));
    },
    reset({ commit }) {
      // eslint-disable-next-line no-console
      commit('resetState');
    },
  },
};
