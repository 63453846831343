import Vue from 'vue';
import VueRouter from 'vue-router';
import MapTester from '../views/MapTester.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/maptester',
    name: 'MapTester',
    component: MapTester,
  },
  {
    path: '/newalert',
    name: 'NewAlert',
    component: () => import(/* webpackChunkName: "newAlert" */ '../views/NewAlert.vue'),
  },
  {
    path: '/alertDetails/:alertId',
    name: 'AlertDetails',
    component: () => import(/* webpackChunkName: "alertDetails" */ '../views/AlertDetails.vue'),
  },
  {
    path: '/areas',
    name: 'Areas',
    component: () => import(/* webpackChunkName: "areas" */ '../views/Areas.vue'),
  },
  {
    path: '/alertlog',
    name: 'AlertLog',
    component: () => import(/* webpackChunkName: "alertlog" */ '../views/AlertLog.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { allowAnonymous: true, hideNavigation: true },
  },
  {
    path: '/subscriberLists',
    name: 'SubscriberLists',
    component: () =>
      import(/* webpackChunkName: "subscriberLists" */ '@/views/SubscriberLists/SubscriberLists'),
  },
  {
    path: '/textTemplate/:id?',
    name: 'TextTemplate',
    component: () =>
      import(/* webpackChunkName: "textTemplate" */ '@/views/TextTemplates/TextTemplate'),
  },
  {
    path: '/textTemplates',
    name: 'TextTemplates',
    component: () =>
      import(/* webpackChunkName: "textTemplates" */ '@/views/TextTemplates/TextTemplates'),
  },
  {
    path: '/v24Sso',
    name: 'V24Sso',
    component: () => import(/* webpackChunkName: "v24Sso" */ '@/views/V24Sso'),
    meta: { allowAnonymous: true, hideNavigation: true },
  },
  {
    path: '/404',
    alias: '*',
    name: 'NotFound',
    component: () => import(/* webapckChunkName: "notFound */ '../views/NotFound.vue'),
    meta: { allowAnonymous: true },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    // Open for all, does NOT need to login
    next();
  } else {
    const isLoggedIn = await store.dispatch('user/isLoggedIn');
    if (!isLoggedIn) {
      next({
        name: 'Login',
      });
    } else {
      next();
    }
  }
});
export default router;
