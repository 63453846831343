<template>
  <div
    :style="{ ...mapSize }"
    :class="{
      'clusters-muted': clustersMuted,
      'rounded-corners': roundedCorners,
    }"
    @keypress.enter="stopDrawing"
    @keypress.c="copyCoordinatesFromCurrentDrawing"
  >
    <l-map
      v-if="showMap"
      ref="map"
      :zoom.sync="zoom"
      :center.sync="currentCenter"
      :options="mapOptions"
      :style="{ ...mapSize }"
      :class="{ 'rounded-corners': roundedCorners }"
      @ready="onMapReady"
    >
      <l-tile-layer
        :url="selectedMapTypeInfo.url"
        :attribution="attribution"
        :subdomains="selectedMapTypeInfo.subdomains"
      />

      <l-polygon
        v-if="showLimits"
        fill-color="#000000"
        :fill-opacity="0.3"
        :stroke="true"
        color="#828282"
        :weight="1"
        :lat-lngs="inverseFilledLimits"
      />

      <template v-for="(polygon, index) in polygons">
        <l-polygon :key="index" :lat-lngs="polygon" />
      </template>
      <template v-if="predefinedArea && showPredefinedArea">
        <l-polygon :lat-lngs="predefinedArea" />
      </template>

      <template v-for="(polygon, index) in currentDrawingIntersections">
        <l-polygon :key="index" :lat-lngs="polygon" />
      </template>

      <l-control v-if="drawTools" position="topleft">
        <div class="d-flex flex-row">
          <v-btn :disabled="isDrawing" aria-label="Draw control" small @click="startPolygon">
            <v-icon small left>fal fa-draw-polygon</v-icon>{{ $t('map.draw') }}
          </v-btn>
          <v-btn
            v-if="!currentDrawing"
            v-show="isDrawing"
            class="ml-1"
            aria-label="Draw control"
            small
            @click="stopDrawing"
          >
            <v-icon small left>fal fa-draw-polygon</v-icon>{{ $t('map.cancel') }}
          </v-btn>
          <v-btn
            v-if="currentDrawing"
            class="ml-1"
            aria-label="Draw control"
            small
            @click="clearDrawing"
          >
            <v-icon small left>fal fa-eraser</v-icon>{{ $t('map.remove') }}
          </v-btn>
          <!-- <v-btn class="mt-1" aria-label="Draw control" @click="startCircle">
            <v-icon small left>fal fa-circle</v-icon>Sirkel
          </v-btn> -->
        </div>
      </l-control>

      <!-- <l-control v-if="isDrawing" position="topleft">
        <v-alert>Press 'Enter' to finish polygon.</v-alert>
      </l-control> -->

      <l-control position="topright">
        <div class="d-flex flex-column">
          <v-btn aria-label="Zoom in" class="mb-1" small @click="zoom++"
            ><v-icon small>fal fa-plus</v-icon></v-btn
          >
          <v-btn aria-label="Zoom out" small @click="zoom--"
            ><v-icon small>fal fa-minus</v-icon></v-btn
          >
        </div>
      </l-control>
      <l-control>
        <v-btn aria-label="Fullscreen" small @click="toggleFullscreen">
          <v-icon v-if="!isFullscreen" small> fal fa-expand</v-icon>
          <v-icon v-else small> fal fa-compress</v-icon>
        </v-btn>
      </l-control>
      <l-control position="bottomright">
        <div id="mapLayerMenu" class="map-layer-menu" />
        <v-menu top left offset-y attach="#mapLayerMenu">
          <template #activator="{ on, attrs }">
            <v-btn color="primary" dark v-bind="attrs" aria-label="Select maplayer" v-on="on">
              <v-icon>fal fa-layer-group</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(enabledMapType, index) in enabledMapTypes"
              :key="index"
              @click="setSelectedMapType(enabledMapType.value)"
            >
              <v-list-item-icon>
                <v-icon v-if="enabledMapType.value === selectedMapType">fal fa-check-square</v-icon>
                <v-icon v-else>fal fa-square</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t(enabledMapType.name) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </l-control>
      <l-marker-cluster
        :options="{
          singleMarkerMode: true,
          iconCreateFunction: iconCreateFunction,
          spiderfyOnMaxZoom: false,
        }"
      >
        <l-marker
          v-for="l in markers"
          :key="l.id"
          :lat-lng="l.latLng"
          :options="{ count: l.text }"
        />
      </l-marker-cluster>
      <l-marker-cluster
        ref="percentMarkers"
        :options="{
          singleMarkerMode: true,
          iconCreateFunction: percentIconCreateFunction,
          spiderfyOnMaxZoom: true,
        }"
      >
        <l-marker
          v-for="l in percentMarkers"
          :key="l.id"
          :lat-lng="l.latLng"
          :options="{ total: l.total, delivered: l.delivered }"
        />
      </l-marker-cluster>
      <geosearch-wrapper v-if="searchControl" :options="geosearchOptions" />
    </l-map>
    <v-snackbar v-model="snackbar" :timeout="4000">
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          {{ $t('map.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css';
import { latLng, icon, polygon, divIcon } from 'leaflet';
import { LMap, LTileLayer, LMarker, LControl, LPolygon } from 'vue2-leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-editable';
import * as turf from '@turf/helpers';
import turfIntersect from '@turf/intersect';
import turfArea from '@turf/area';
import turfUnion from '@turf/union';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import onResize from '@/util/onResize';
import 'leaflet-fullscreen';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import GeosearchWrapper from '@/components/GeosearchWrapper.vue';

const ALLOW_SAVING_MULTIPOLYGON = true;

const polygonPrintFormats = ['multiPolygonWithLatLngObjects', 'multiPolygonWithLatLngArrays'];
const polygonPrintFormat = polygonPrintFormats[1];
const polygonPrintFormatIndent = false;

const googleSubdomains = ['mt0', 'mt1', 'mt2', 'mt3'];
const googleMapTileUrl = (lyrs) =>
  `https://{s}.google.com/vt/lyrs=${lyrs}&x={x}&y={y}&z={z}&language=en`;

// See https://stackoverflow.com/questions/33343881/leaflet-in-google-maps#answer-58811749
const allMapTypes = {
  streets: {
    url: googleMapTileUrl('m'),
    subdomains: googleSubdomains,
    name: 'map.road',
  },
  satellite: {
    url: googleMapTileUrl('s'),
    subdomains: googleSubdomains,
    name: 'map.satellite',
  },
  hybrid: {
    url: googleMapTileUrl('s,h'),
    subdomains: googleSubdomains,
    name: 'map.hybrid',
  },
  terrain: {
    url: googleMapTileUrl('p'),
    subdomains: googleSubdomains,
    name: 'map.terrain',
  },
  kartverket: {
    url: 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=topo4graatone&format=image/jpeg&zoom={z}&x={x}&y={y}',
    subdomains: googleSubdomains,
    name: 'map.grayscale',
  },
};

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPolygon,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
    GeosearchWrapper,
  },

  props: {
    center: {
      type: [Array, Object],
      default: null,
    },
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '200px',
    },
    size: {
      type: [String, Number],
      default: null,
    },
    mapTypes: {
      type: Array,
      default: () => Object.keys(allMapTypes),
    },
    markers: {
      type: Array,
      default: () => [],
    },
    percentMarkers: {
      type: Array,
      default: () => [],
    },
    drawTools: {
      type: Boolean,
      default: false,
    },
    limits: {
      type: Array,
      default: () => [],
    },
    predefinedArea: {
      type: Array,
      default: null,
    },
    fullscreenControl: {
      type: Boolean,
      default: false,
    },
    clustersMuted: {
      type: Boolean,
      default: false,
    },
    searchControl: {
      type: Boolean,
      dafault: false,
    },
    roundedCorners: {
      type: Boolean,
      default: false,
    },
  },

  data: (self) => ({
    zoom: 8,
    currentCenter: latLng(0, 0),

    attribution:
      '&copy; <a href="https://about.google/brand-resource-center/products-and-services/geo-guidelines/">Google Maps</a>',
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
      minZoom: 3,
      maxBounds: [
        [-90, -180],
        [90, 180],
      ],
      zoomControl: false,
    },
    showMap: true,
    defaultMarkerIcon: self.createIcon({
      iconUrl: require('@/assets/images/marker-icon.png'),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    }),

    map: null,

    polygons: [],

    currentDrawing: null,
    currentDrawingIntersections: [],
    snackbar: false,
    snackbarMessage: '',
    showPredefinedArea: true,
    isFullscreen: false,
    geosearchOptions: {
      // Important part Here
      provider: new OpenStreetMapProvider({
        params: {
          'accept-language': 'no',
          countrycodes: 'no',
        },
      }),
      style: 'bar',
      position: 'topright',
      showMarker: false,
      showPopup: false,
      autoClose: true,
      searchLabel: self.$t('map.writeAddress'),
      keepResult: false,
    },
    isDrawing: false,
  }),

  computed: {
    selectedMapType() {
      return this.$store.state.user.mapType;
    },
    enabledMapTypes() {
      const enabled = [];
      Object.keys(allMapTypes).forEach((mt) => {
        if (this.mapTypes.includes(mt)) {
          enabled.push({
            ...allMapTypes[mt],
            value: mt,
          });
        }
      });
      return enabled;
    },
    mapSize() {
      let width = this.width;
      let height = this.height;
      if (this.size != null) {
        width = height = this.size;
      }
      return {
        width,
        height,
      };
    },
    selectedMapTypeInfo() {
      return allMapTypes[this.selectedMapType];
    },
    // isDrawing() {
    //   console.info("IsDrawing: " + this.currentDrawing?.editor?.drawing());
    //   return Boolean(this.currentDrawing?.editor?.drawing());
    // },
    showLimits() {
      return this.limits && this.limits.length > 0;
    },
    inverseFilledLimits() {
      if (!this.limits) {
        return [];
      }
      return [
        [
          { lat: 90, lng: 99999999 },
          { lat: 90, lng: -99999999 },
          { lat: -90, lng: -99999999 },
          { lat: -90, lng: 99999999 },
        ], // Outer polygon (where you cannot draw)
        ...this.limits, // Inner polygons (where you can draw)
      ];
    },

    locale() {
      return this.$store.state.user.locale;
    },
  },

  watch: {
    // percentMarkers() {
    //   console.info('percentmarkers changed');
    //   let test = this.$refs.percentMarkers;
    //   console.info('percentmarkers changed', test);
    //   let children = test.getAllChildMarkers();
    //   console.info('children', children);
    //   test.refreshClusters();
    // },
    center() {
      this.updateCenter();
    },

    drawTools() {
      if (!this.drawTools) {
        if (this.currentDrawing) {
          this.currentDrawing.remove();
          this.currentDrawing = null;
        }
        this.stopDrawing();
      }
    },

    inverseFilledLimits() {
      this.fitToLimits();
    },
    predefinedArea() {
      if (this.predefinedArea) this.fitToPredefinedArea();
    },

    locale() {
      if (this.map) {
        this.map.pm.setLang(this.locale);
      }
    },
  },

  created() {
    this.updateCenter();
  },

  mounted() {
    onResize(this.$el, () => {
      if (this?.$el?.isConnected) this.redraw();
    });
  },

  methods: {
    updateCenter() {
      if (this.center) {
        this.currentCenter = latLng(this.center);
      }
    },

    createIcon(opts) {
      return icon(opts);
    },

    redraw() {
      this.map.invalidateSize(false);
    },

    onMapReady() {
      this.map = this.$refs.map.mapObject;
      this.map.pm.setLang(this.locale);
      this.map.on('fullscreenchange', () => {
        this.isFullscreen = this.map.isFullscreen();
      });
      this.redraw();
      // const drawEvents = [
      //   // 'editable:created',
      //   // 'editable:enable',
      //   // 'editable:disable',
      //   // 'editable:editing',
      //   // 'editable:dragstart',
      //   // 'editable:drag',
      //   // 'editable:dragend',
      //   // 'editable:drawing:start',
      //   // 'editable:drawing:end',
      //   // 'editable:drawing:cancel',
      //   // 'editable:drawing:commit',
      //   // 'editable:drawing:mousedown',
      //   // 'editable:drawing:mouseup',
      //   // 'editable:drawing:click',
      //   // 'editable:drawing:move',
      //   // 'editable:drawing:clicked',
      //   // 'editable:vertex:dragend',
      //   'pm:globaldrawmodetoggled',
      //   'pm:drawstart',
      //   'pm:drawend',
      //   'pm:create',
      // ];

      // const fitCurrentDrawingToLimitsTriggers = [
      //   'editable:drawing:clicked',
      //   'editable:vertex:dragend'
      // ];

      // fitCurrentDrawingToLimitsTriggers.forEach((trigger) =>
      //   this.map.on(trigger, this.fitCurrentDrawingToLimits));

      this.map.on('pm:create', (event) => {
        this.enableEdit(event.layer);
        // TODO remove stopp knapp
        this.isDrawing = false;
      });
      //  this.map.on('pm:globaldrawmodetoggled', (event) => {
      //    console.info("globaldrawmodetoggled", event);
      //   this.isDrawing = event.enabled;
      // });

      this.$emit('ready');
    },

    enableEdit(layer) {
      this.currentDrawing = layer;

      this.fitCurrentDrawingToLimits();

      this.currentDrawing.setStyle({
        opacity: 0.5,
        color: '#AAAAAA',
        dashArray: '4',
        fill: false,
      });

      this.currentDrawing.pm.enable({
        snappable: true,
        snapDistance: 20,
        allowSelfIntersection: false,
      });

      // const layerEvents = [
      //   // 'pm:update',
      //   // 'pm:enable',
      //   // 'pm:disable',
      //   // 'pm:vertexadded',
      //   'pm:markerdragend',
      // ];

      this.currentDrawing.on('pm:markerdragend', this.fitCurrentDrawingToLimits);
    },

    startPolygon() {
      this.isDrawing = true;
      if (this.currentDrawing) {
        this.currentDrawing.remove();
        this.currentDrawing = null;
      }

      this.map.pm.enableDraw('Polygon', {
        snappable: true,
        snapDistance: 20,
        allowSelfIntersection: false,
        templineStyle: {
          dashArray: '4',
        },
        hintlineStyle: {
          dashArray: '4',
        },
        pathOptions: {
          opacity: 0.5,
          color: '#AAAAAA',
          dashArray: '4',
          fill: false,
        },
      });
      this.$emit('startPolygon');
      // this.currentDrawing = this.map.editTools.startPolygon(null, {
      //   color: '#AAAAAA',
      //   opacity: 0.5
      // });
    },

    startCircle() {
      if (this.currentDrawing) {
        this.currentDrawing.remove();
        this.currentDrawing = null;
      }

      this.map.pm.enableDraw('Circle', {
        showTootip: true,
        radius: true,
        templineStyle: {
          dashArray: '4',
        },
        hintlineStyle: {
          dashArray: '4',
        },
        pathOptions: {
          opacity: 0.5,
          color: '#AAAAAA',
          dashArray: '4',
          fill: false,
        },
      });

      // this.currentDrawing = this.map.editTools.startPolygon(null, {
      //   color: '#AAAAAA',
      //   opacity: 0.5
      // });
    },

    stopDrawing() {
      this.isDrawing = false;
      this.map.pm.disableDraw();
      if (this.currentDrawing) {
        this.currentDrawing.remove();
        this.currentDrawing = null;
        this.currentDrawingIntersections = [];
      }
      this.$emit('stopDrawing');
    },
    clearDrawing() {
      this.isDrawing = false;
      if (this.currentDrawing) {
        this.currentDrawing.remove();
        this.currentDrawing = null;
        this.currentDrawingIntersections = [];
      }
      if (this.predefinedArea) {
        this.showPredefinedArea = false;
      }
      this.$emit('clearedDrawing');
    },

    async copyCoordinatesFromCurrentDrawing() {
      let multipolygon = null;
      if (this.currentDrawingIntersections) {
        multipolygon = this.currentDrawingIntersections;
      }

      if (multipolygon) {
        if (polygonPrintFormat === polygonPrintFormats[1]) {
          multipolygon = multipolygon.map((p) => p.map((v) => [v.lat, v.lng]));
        }

        const indentation = polygonPrintFormatIndent ? 2 : 0;

        const polygonString = JSON.stringify(multipolygon, null, indentation);
        await navigator.clipboard.writeText(polygonString);
        this.snackbar = true;
        this.snackbarMessage = 'Copied multipolygon to clipboard';
      }
    },

    fitToLimits() {
      if (this.limits) {
        const limitsPolygon = polygon(this.limits);
        const limitsBounds = limitsPolygon.getBounds();
        this.map.fitBounds(limitsBounds);
      }
    },
    fitToPredefinedArea() {
      if (this.predefinedArea) {
        this.showPredefinedArea = false;
        const predefinedAreaPolygon = polygon(this.predefinedArea);
        const predefinedAreaBounds = predefinedAreaPolygon.getBounds();
        this.showPredefinedArea = true;
        this.map.fitBounds(predefinedAreaBounds, { padding: [90, 90] });
      }
    },
    fitCurrentDrawingToLimits() {
      if (this.limits && this.currentDrawing && this.currentDrawing.getLatLngs()[0].length >= 3) {
        const limitsPolygons = this.limits.map((p) => {
          const newP = p.map((ll) => [ll.lat, ll.lng]);
          newP.push(newP[0]);
          return newP;
        });

        const drawingLatLngs = this.currentDrawing.getLatLngs()[0].map((ll) => [ll.lat, ll.lng]);
        drawingLatLngs.push(drawingLatLngs[0]);

        const turfLimitsPolygons = limitsPolygons.map((limitsLatLngs) =>
          turf.polygon([limitsLatLngs]),
        );
        const turfDrawingPolygon = turf.polygon([drawingLatLngs]);

        let intersections = turfLimitsPolygons.map((turfLimitsPolygon) =>
          turfIntersect(turfLimitsPolygon, turfDrawingPolygon),
        );

        intersections = intersections.filter((possibleIntersection) => possibleIntersection);

        if (!ALLOW_SAVING_MULTIPOLYGON) {
          intersections.sort((a, b) => {
            const aSize = turfArea(a);
            const bSize = turfArea(b);
            return bSize - aSize;
          });

          intersections = [intersections[0]];
        } else {
          intersections.forEach((intersectionA, index) => {
            intersections.forEach((intersectionB) => {
              intersections[index] = turfUnion(intersectionA, intersectionB);
            });
          });
        }

        if (intersections) {
          const newCurrentDrawingIntersectionsCoords = intersections.map((i) => {
            const newCoords = i.geometry.coordinates;
            newCoords[0].pop();
            return newCoords[0].map((polygon) => ({
              lat: polygon[0],
              lng: polygon[1],
            }));
          });
          this.currentDrawingIntersections = newCurrentDrawingIntersectionsCoords;
          this.$emit('finishedDrawing', this.currentDrawingIntersections);
        }
      }
    },

    turfFriendlyLimitsLatLngs() {
      return this.limits.map((p) => {
        const newP = p.map((ll) => [ll.lat, ll.lng]);
        newP.push(newP[0]);
        return newP;
      });
    },

    turfFriendlyCurrentDrawingLatLngs() {
      const currentDrawingLatLngs = this.currentDrawing.getLatLngs()[0];
      const asArray = currentDrawingLatLngs.map((ll) => [ll.lat, ll.lng]);
      asArray.push(asArray[0]);
      return [asArray];
    },
    setSelectedMapType(mapType) {
      this.$store.dispatch('user/setMapType', mapType).catch((error) => {
        alert(error);
      });
    },
    iconCreateFunction(cluster) {
      let counter = 0;
      cluster.getAllChildMarkers().forEach((marker) => {
        counter += marker.options.count;
      });
      return divIcon({
        html: '<div><span>' + counter + '</span></div>',
        className: 'marker-cluster marker-cluster-large',
      });
    },
    percentIconCreateFunction(cluster) {
      let percent = 0;
      let total = 0;
      let delivered = 0;
      cluster.getAllChildMarkers().forEach((marker) => {
        total += marker.options.total;
        delivered += marker.options.delivered;
      });
      if (total > 0) {
        percent = Math.round((delivered / total) * 100);
      } else {
        percent = 100;
      }

      let clusterColor = this.getDeliveryClusterColor(percent);
      return divIcon({
        html: '<div><span>' + percent + '%</span></div>',
        className: 'marker-cluster marker-cluster-large ' + clusterColor,
      });
    },
    getDeliveryClusterColor(percent) {
      if (percent >= 95) return 'green';
      else if (percent >= 80) return 'yellow-green';
      else if (percent >= 50) return 'orange';
      else if (percent >= 20) return 'dark-orange';
    },
    toggleFullscreen() {
      this.map?.toggleFullscreen();
    },
  },
};
</script>

<style lang="scss">
@import '~leaflet.markercluster/dist/MarkerCluster.css';
@import '~leaflet.markercluster/dist/MarkerCluster.Default.css';

.rounded-corners {
  border-radius: 4px;
}
.map-layer-menu {
  width: 200px;
  right: -136px;
  position: absolute;
  bottom: 45px;
}
.vue2leaflet-map {
  z-index: 1;
  &.bordered {
    border-radius: 4px;
  }
}
.clusters-muted .marker-cluster {
  opacity: 0.2 !important;
}

.marker-cluster {
  background-clip: padding-box;
  border-radius: 45px;

  div {
    width: 50px;
    height: 50px;
    margin-left: 5px;
    margin-top: 5px;
    color: white;
    text-align: center;
    border-radius: 45px;
    font: 12px 'Helvetica Neue', Arial, Helvetica, sans-serif;

    span {
      line-height: 50px;
    }
  }
}

.marker-cluster-large {
  background-color: rgba(125, 5, 5, 0.85); //#305c77;
  border-radius: 26px !important;
  height: 50px !important;
  width: 50px !important;
  margin-left: -25px !important;
  margin-top: -25px !important;

  &.green {
    background-color: rgba(8, 93, 05, 0.85) !important;
    div {
      background-color: #2d7631;
    }
  }
  &.yellow-green {
    background-color: rgba(143, 181, 12, 0.85);
    div {
      background-color: #bcd611;
      & > span {
        color: black;
      }
    }
  }
  &.orange {
    background-color: rgba(170, 91, 0, 0.85) !important;
    div {
      background-color: rgb(202, 121, 0);
    }
  }
  &.dark-orange {
    background-color: rgba(165, 74, 2, 0.85);
    div {
      background-color: #b95302;
    }
  }

  div {
    background-color: rgba(177, 0, 0, 0.6); //rgba(69,123,157,0.6);// rgba(177, 0, 0, 0.6);
    border-radius: 20px !important;
    height: 40px !important;
    width: 40px !important;
    & > span {
      line-height: 40px;
      color: #fff;
      // font-weight: bolder;
      line-height: 40px;
    }
  }
}

/* global styling */
.leaflet-control-geosearch *,
.leaflet-control-geosearch *:before,
.leaflet-control-geosearch *:after {
  box-sizing: border-box;
}

/* leaflet button styling */
.leaflet-control-geosearch .leaflet-bar-part {
  border-radius: 4px;
  border-bottom: none;
}

.leaflet-control-geosearch a.leaflet-bar-part:before,
.leaflet-control-geosearch a.leaflet-bar-part:after {
  position: absolute;
  display: block;
  content: '';
}

/* magnifying glass */
.leaflet-control-geosearch a.leaflet-bar-part:before {
  top: 19px;
  left: 16px;
  width: 8px;
  border-top: 2px solid #555;
  transform: rotateZ(45deg);
}

.leaflet-control-geosearch a.leaflet-bar-part:after {
  top: 6px;
  left: 6px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid #555;
}

/* resets for pending and error icons */
.leaflet-control-geosearch.error a.leaflet-bar-part:before,
.leaflet-control-geosearch.pending a.leaflet-bar-part:before {
  display: none;
}

.leaflet-control-geosearch.pending a.leaflet-bar-part:after,
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  margin: -9px 0 0 -9px;
  border-radius: 50%;
}

/* pending icon */
.leaflet-control-geosearch.pending a.leaflet-bar-part:after {
  content: '';
  border: 2px solid #555;
  border-top: 2px solid #f3f3f3;
  animation: spin 1s linear infinite;
}

/* error icon */
.leaflet-control-geosearch.error a.leaflet-bar-part:after {
  content: '!';
  line-height: initial;
  font-weight: 600;
  font-size: 18px;
  border: none;
}

/* search form styling */
.leaflet-control-geosearch form {
  display: none;
  position: absolute;
  top: -2px;
  left: 28px;
  border-radius: 0 4px 4px 0;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-left: none;
  background-color: #fff;
  background-clip: padding-box;
  z-index: -1;
  height: auto;
  margin: 0;
  padding: 0 8px;
}

.leaflet-control-geosearch.active form {
  display: block;
}

.leaflet-control-geosearch form input {
  min-width: 200px;
  width: 100%;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 12px;
  height: 30px;
  border-radius: 0 4px 4px 0;
  text-indent: 8px;
}

.leaflet-control-geosearch .results {
  background: #fff;
}

.leaflet-control-geosearch .results > * {
  line-height: 24px;
  padding: 0 8px;
  border: 1px solid transparent;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leaflet-control-geosearch .results.active {
  padding: 8px 0;
  border-top: 1px solid #c6c6c6;
}

.leaflet-control-geosearch .results > .active,
.leaflet-control-geosearch .results > :hover {
  background-color: #f8f8f8;
  border-color: #c6c6c6;
  cursor: pointer;
}

/* add missing border to form */
.leaflet-control-geosearch .results.active:after {
  content: '';
  display: block;
  width: 0;
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -2px;
  bottom: -2px;
  top: 30px;
}

/* animations */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.leaflet-top .leaflet-control-geosearch.leaflet-geosearch-bar,
.leaflet-bottom .leaflet-control-geosearch.leaflet-geosearch-bar {
  display: none;
}

.leaflet-control-geosearch.leaflet-geosearch-bar {
  position: relative;
  display: block;
  height: auto;
  width: 400px;
  margin: 10px auto 0;
  cursor: auto;
  z-index: 1000;
}

.leaflet-control-geosearch.leaflet-geosearch-bar form {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.leaflet-control-geosearch.leaflet-geosearch-bar form input {
  min-width: 100%;
  width: 100%;
}

.leaflet-control-geosearch.leaflet-geosearch-bar .results.active:after {
  opacity: 0.2;
}

.leaflet-right .leaflet-control-geosearch form {
  right: 28px;
  left: initial;
  border-radius: 4px 0 0 4px;
  border-left: inherit;
  border-right: none;
}

.leaflet-control-geosearch a.reset {
  color: black;
  position: absolute;
  line-height: 30px;
  padding: 0 8px;
  right: 0;
  top: 0;
  cursor: pointer;
  border: none;
}

.leaflet-control-geosearch a.reset:hover {
  background: #f5f5f5;
}
</style>
