<template>
  <div />
</template>

<script>
// The component https://github.com/fega/vue2-leaflet-geosearch is no longer maintained
import { GeoSearchControl } from 'leaflet-geosearch';

export default {
  name: 'GeosearchWrapper',
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.add();
  },
  beforeDestroy() {
    this.remove();
  },
  methods: {
    deferredMountedTo(parent) {
      const searchControl = new GeoSearchControl(this.options);
      parent.addControl(searchControl);
      searchControl.getContainer().onclick = (e) => {
        e.stopPropagation();
      };
    },
    remove() {
      if (this.markerCluster) {
        this.$parent.removeLayer(this.markerCluster);
      }
    },
    add() {
      if (this.$parent._isMounted) {
        this.deferredMountedTo(this.$parent.mapObject);
      }
    },
  },
};
</script>
