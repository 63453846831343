import i18n, { selectedLocale } from '@/plugins/i18n';
import laApiInstance from '@/util/laApi';
import { errLog } from '@/util/logger';
import moment from 'moment';

const laApi = laApiInstance();

const getDefaultState = () => ({
  locale: selectedLocale,
  jwt: '',
  mapType: 'streets',
  predefinedAreas: [],
  isAuthenticated: false,
  profile: null,
  apiStatus: null,
  statusIo: null,
  statusIoSubscription: null,
  simulateMode: false,
  statusByCountryExpanded: false,
  subscriberLists: [],
  currentSubscriberList: null,
  loggedInFromV24: false,
});

export default {
  namespaced: true,
  state: getDefaultState(),

  getters: {},
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      Object.assign(state, {
        ...getDefaultState(),
        locale: state.locale,
        mapType: state.mapType,
        statusByCountryExpanded: state.statusByCountryExpanded,
      });
    },
    updateLocale(state, newLocale) {
      state.locale = newLocale;
    },
    setIsAuthenticated(state, auth) {
      state.isAuthenticated = auth;
    },
    setProfile(state, profile) {
      state.profile = profile;
    },
    setJwt(state, jwt) {
      state.jwt = jwt;
    },
    setLoggedInFromV24(state, value) {
      state.loggedInFromV24 = value;
    },
    setMapType(state, mapType) {
      state.mapType = mapType;
    },
    setPredefinedAreas(state, areas) {
      state.predefinedAreas = areas;
    },
    setApiStatus(state, status) {
      state.apiStatus = status;
    },
    setStatusIo(state, status) {
      state.statusIo = status;
    },
    setStatusIoSubscription(state, subs) {
      state.statusIoSubscription = subs;
    },
    setSimulateMode(state, isSimulate) {
      state.simulateMode = isSimulate;
    },
    setStatusByCountryExpanded(state, value) {
      state.statusByCountryExpanded = value;
    },
    setCurrentSubscriberList(state, value) {
      state.currentSubscriberList = value;
    },
    setSubscriberLists(state, value) {
      value.sort((a, b) => a.id - b.id);
      state.subscriberLists = value;
    },
    addSubscriberList(state, value) {
      const newList = [...state.subscriberLists, value];
      newList.sort((a, b) => a.id - b.id);
      state.subscriberLists = newList;
    },
    updateSubscriberList(state, value) {
      const existing = state.subscriberLists.find((existing) => existing.id === value.id);

      if (existing) {
        const newList = [
          ...state.subscriberLists.filter((existing) => existing.id !== value.id),
          value,
        ];
        newList.sort((a, b) => a.id - b.id);
        state.subscriberLists = newList;
      }
    },
  },
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale;
      commit('updateLocale', newLocale);
    },
    async login({ commit }, loginRequest) {
      const response = await laApi.post('login', loginRequest);
      if (response.status == 200) {
        try {
          if (!response.data.challengeSmsPinCode) {
            commit('setIsAuthenticated', true);
            commit('setProfile', response.data.authenticatedUser);
            const newJwt = response.headers['set-token'];
            if (newJwt) {
              commit('setJwt', newJwt);
            }
          }
        } catch (error) {
          errLog(error);
        }
      }
      return response;
    },
    async loginWithSessionId({ commit }, sessionId) {
      const response = await laApi.post('login/sessionId', {
        sessionId,
      });
      if (response.status == 200) {
        try {
          commit('setIsAuthenticated', true);
          commit('setProfile', response.data.authenticatedUser);
          commit('setLoggedInFromV24', true);
          const newJwt = response.headers['set-token'];
          if (newJwt) {
            commit('setJwt', newJwt);
          }
        } catch (error) {
          errLog(error);
        }
      }
      return response;
    },
    async loginWithSessionTicket({ commit }, sessionTicket) {
      const response = await laApi.post('login/sessionTicket', {
        ticket: sessionTicket,
      });
      if (response.status == 200) {
        try {
          commit('setIsAuthenticated', true);
          commit('setProfile', response.data.authenticatedUser);
          commit('setLoggedInFromV24', true);
          const newJwt = response.headers['set-token'];
          if (newJwt) {
            commit('setJwt', newJwt);
          }
        } catch (error) {
          errLog(error);
        }
      }
      return response;
    },
    async verifyPinCode({ commit }, loginRequest) {
      const response = await laApi.post('login/verifyPinCode', loginRequest);
      if (response.status == 200) {
        try {
          commit('setIsAuthenticated', true);
          commit('setProfile', response.data.authenticatedUser);
          // commit('setSessionId', response.data.sessionId);
          const newJwt = response.headers['set-token'];
          if (newJwt) {
            commit('setJwt', newJwt);
          }
        } catch (error) {
          errLog(error);
        }
      }
      return response;
    },
    async logOut({ commit, dispatch }) {
      try {
        await laApi.post('logout');
      } catch (error) {
        errLog(error);
      }
      dispatch('alert/resetWizard', {}, { root: true });
      dispatch('alertLog/reset', {}, { root: true });
      dispatch('alertStatus/reset', {}, { root: true });
      dispatch('textTemplate/reset', {}, { root: true });
      commit('resetState');
    },
    setMapType({ commit }, mapType) {
      commit('setMapType', mapType);
    },
    getPredefinedAreas({ commit }) {
      return laApi
        .get('locations/')
        .then((response) => commit('setPredefinedAreas', response.data));
    },
    createArea({ dispatch }, area) {
      return laApi.post('locations/', area).then((res) => {
        dispatch('getPredefinedAreas');
        return res.data;
      });
    },
    updateArea({ dispatch }, area) {
      return laApi.put(`locations/${area.id}`, area).then(() => {
        dispatch('getPredefinedAreas');
      });
    },
    deleteArea({ dispatch }, id) {
      return laApi.delete('locations/' + id).then(() => dispatch('getPredefinedAreas'));
    },
    async getApiStatus({ commit }) {
      try {
        const res = await laApi.get('apistatus');
        if (res.status == 200) {
          commit('setApiStatus', res.data);
          return res.data;
        }
      } catch (error) {
        commit('setApiStatus', null);
        errLog(error);
      }
    },
    async getStatusIo({ commit }) {
      //  if (state.statusIo == null) { //Restrict calls to once pr session?
      try {
        const res = await laApi.get('statusIo');

        if (res.status == 200) {

          // Filter out upcoming maintenance events that are more than 24h into the future.
          if (res.data?.maintenance?.upcoming) {
            res.data.maintenance.upcoming = res.data.maintenance.upcoming.filter(item => 
              !item.datetimePlannedStart || moment(item.datetimePlannedStart).isBefore(moment().add(24, "hours"))
            )
          }

          commit('setStatusIo', res.data);
          return res.data;
        }
      } catch (error) {
        commit('setStatusIo', null);
        errLog(error);
      }
      // }
    },
    async getSubscriberLists({ commit }) {
      const res = await laApi.get('subscriberLists');
      commit('setSubscriberLists', res.data);
      return res.data;
    },
    async getSubscriberList({ commit }, id) {
      const res = await laApi.get(`subscriberLists/${id}`);
      commit('setCurrentSubscriberList', res.data);
      return res.data;
    },
    async deleteSubscriberList({ dispatch }, id) {
      await laApi.delete(`subscriberLists/${id}`);
      await dispatch('getSubscriberLists');
    },
    async createSubscriberList({ dispatch }, list) {
      const res = await laApi.post('subscriberLists', list);
      await dispatch('getSubscriberLists');
      return res.data;
    },
    async updateSubscriberList({ dispatch }, list) {
      await laApi.put(`subscriberLists/${list.id}`, list);
      await dispatch('getSubscriberLists');
    },
    async isLoggedIn({ state }) {
      if (!state.jwt || !state.isAuthenticated) {
        return false;
      }

      try {
        const res = await laApi.get('login');
        return res?.status === 200;
      } catch {
        return false;
      }
    },
    async getStatusIoSubscriptions({ commit }) {
      try {
        const res = await laApi.get('statusIo/subscriptions');
        if (res.status == 200) {
          commit('setStatusIoSubscription', res.data);
          return res.data;
        } else if (res.status == 204) {
          commit('setStatusIoSubscription', null);
        }
      } catch (error) {
        // commit('setStatusIo', null);
        errLog(error);
      }
    },
    async addStatusIoSubscriber({ commit }, body) {
      const res = await laApi.post('statusIo/addSubscriber', body);
      if (res.status == 200) {
        // insert dummydata since it takes time for the subscription to be created
        commit('setStatusIoSubscription', { active: true, address: body.address });
      }
    },
  },
};
