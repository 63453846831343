import laApiInstance from '@/util/laApi';
import * as numberTools from '@/util/numberTools';
const laApi = laApiInstance();

const getDefaultState = () => ({
  alertName: '',
  countryResult: null,
  cellResult: null,
  alertTarget: null,
  countryMessages: [],
  alertStatus: null,
  alertStatusByCountry: null,
  alertStatusByCell: null,
  predefinedAreas: null,
  defaultMessage: '',
  defaultMessageEncoding: 'gsm-7',
  cellMarkers: [],
  subscriberLists: [],
  selectedIncludeList: null,
  selectedPredefinedArea: null,
});

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {
    subscribersByCountryCount(state) {
      if (state.countryResult) return state.countryResult.total;
      else return 0;
    },
    getTotalTeliaSubscribers(state) {
      if (!state.countryResult) {
        return 0;
      } else {
        let teliaIndex = state.countryResult.operators.findIndex(function (object) {
          return object.name === 'telia';
        });
        let teliaSubs = state.countryResult.operators[teliaIndex].total;

        return numberTools.formatNumber(teliaSubs);
      }
    },
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setAlertName(state, name) {
      state.alertName = name;
    },
    setCountryResult(state, result) {
      state.countryResult = result;
    },
    setCellResult(state, result) {
      state.cellResult = result;
    },
    setCellMarkers(state, markers) {
      state.cellMarkers = markers;
    },
    setAlertTarget(state, target) {
      state.alertTarget = target;
    },
    setCountryMessages(state, countryMessages) {
      state.countryMessages = countryMessages;
    },
    setDefaultMessage(state, message) {
      state.defaultMessage = message;
    },
    setDefaultMessageEncoding(state, encoding) {
      state.defaultMessageEncoding = encoding;
    },
    setSubscriberLists(state, lists) {
      state.subscriberLists = lists;
    },
    setSelectedIncludeList(state, subscriberList) {
      state.selectedIncludeList = subscriberList;
    },
    setSelectedPredefinedArea(state, area) {
      state.selectedPredefinedArea = area;
    },
  },
  actions: {
    resetCountryResult({ commit }) {
      commit('setCountryResult', null);
    },
    resetCellResult({ commit }) {
      commit('setCellResult', null);
    },
    calculateCountries({ commit }, body) {
      return laApi
        .post('areaCalculation/CalculateByCountry', body)
        .then((response) => commit('setCountryResult', response.data));
    },
    calculateCells({ commit }, body) {
      return laApi
        .post('areaCalculation/CalculateByCell', body)
        .then((response) => commit('setCellResult', response.data));
    },
    storeAlertTarget({ commit }, target) {
      commit('setAlertTarget', target);
    },
    setCountryMessage({ commit, state }, countryMessage) {
      if (!countryMessage.message) countryMessage.message = '';
      if (!countryMessage.encoding) countryMessage.encoding = 'gsm-7';

      countryMessage = { ...countryMessage };
      //  countryMessage = { ...countryMessage, message:'', encoding:'gsm-7' };
      let countryMessages = [...state.countryMessages];
      countryMessages.unshift(countryMessage);
      commit('setCountryMessages', countryMessages);
    },
    removeCountryMessage({ commit, state }, countryMessage) {
      const existingMessage = state.countryMessages.find(
        (c) => c.country.code == countryMessage.country.code,
      );
      let countryMessages = [...state.countryMessages];
      if (existingMessage) {
        countryMessages = countryMessages.filter((item) => item !== existingMessage);
      }
      commit('setCountryMessages', countryMessages);
    },
    getSubscriberLists({ commit }) {
      return laApi
        .get('subscriberLists')
        .then((response) => commit('setSubscriberLists', response.data));
    },
    updateCountryMessage({ commit, state }) {
      commit('setCountryMessages', [...state.countryMessages]);
    },
    // eslint-disable-next-line no-unused-vars
    sendAlert({ commit }, body) {
      // without commit, no data is sent....
      return laApi.post('alerts', body);
    },

    resetWizard({ commit }) {
      commit('resetState');
    },
  },
};
