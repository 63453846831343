import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import userModule from './modules/user';
import alertModule from './modules/alert';
import alertLogModule from './modules/alertLog';
import createPersistedState from 'vuex-persistedstate';
import alertStatusModule from './modules/alertStatus';
import textTemplateModule from './modules/textTemplate';

export default new Vuex.Store({
  modules: {
    user: userModule,
    alert: alertModule,
    alertLog: alertLogModule,
    alertStatus: alertStatusModule,
    textTemplate: textTemplateModule,
  },
  plugins: [createPersistedState()],
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
