import axios from 'axios';
import router from '@/util/router';
import store from '@/store';
import { warnLog } from './logger';
import { t } from '@/plugins/i18n';
import appSettings from '@/appSettings';

const trySetValidationErrors = (error) => {
  try {
    const errors = error.response.data.errors;
    const validationErrors = [];
    Object.keys(errors).forEach((key) => {
      const errorsForKey = errors[key];
      errorsForKey.forEach((errorForKey) => {
        validationErrors.push(`${key}: ${errorForKey}`);
      });
    });

    error.validationErrors = validationErrors;
  } catch (e) {
    warnLog('Failed to set validationErrors.', e);
  }
};

const laApiInstance = () => {
  const api = axios.create({
    baseURL: appSettings.locationAlertApi + '/',
    timeout: appSettings.skipTimeout ? 0 : 30000,
    headers: {
      'Content-Type': 'application/json',
      'X-Version': '1.0',
    },
  });

  api.interceptors.request.use(
    (config) => {
      const existingJwt = store.state.user.jwt;
      if (existingJwt) {
        config.headers['Authorization'] = `Bearer ${existingJwt}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => {
      const newJwt = response.headers['set-token'];
      if (newJwt) {
        store.commit('user/setJwt', newJwt);
      }

      return response;
    },

    (error) => {
      const request = error?.request;
      const response = error?.response;

      if (response?.status === 500) {
        alert(t('error500'));
        error.hasBeenHandled = true;
      } else if (
        router.currentRoute.name === 'Login' &&
        (response?.status === 401 || response?.status === 403 || response?.status === 423)
      ) {
        return response;
      }

      const attemptedToLogOut = request.responseURL.indexOf('logout') >= 0;

      if (!attemptedToLogOut && (response?.status === 401 || response?.status === 440)) {
        store.dispatch('user/logOut');
        if (router.currentRoute.name !== 'V24Sso') {
          router.push({ name: 'Login' });
        }
        error.hasBeenHandled = true;
      }

      if (response?.status === 400) {
        trySetValidationErrors(error);
      }

      return Promise.reject(error);
    },
  );

  return api;
};

export default laApiInstance;
