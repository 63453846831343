import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { dateTimeFormats } from '@/locales/dateTimeFormats';
import appSettings from '@/appSettings';
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const availableLanguages = [
  { name: 'Norsk', value: 'no' },
  { name: 'English', value: 'en' },
];
function checkDefaultLanguage() {
  let matched = null;
  let languages = availableLanguages;
  // let languages = Object.getOwnPropertyNames(loadLocaleMessages());

  if (!matched) {
    languages.forEach((lang) => {
      if (lang.value === navigator.language) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split('-')[0];
      if (lang.value === languagePartials) {
        matched = lang;
      }
    });
  }
  if (!matched) {
    languages.forEach((lang) => {
      let languagePartials = navigator.language.split('-')[0];
      if (lang.value.split('-')[0] === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}
export const selectedLocale = checkDefaultLanguage() || appSettings.i18n.locale || 'no';
export const languages = availableLanguages; // Object.getOwnPropertyNames(loadLocaleMessages());

const v_i18n = new VueI18n({
  locale: selectedLocale,
  fallbackLocale: appSettings.i18n.fallbackLocale || 'no',
  messages: loadLocaleMessages(),
  dateTimeFormats,
  silentTranslationWarn: false,
});

export const t = v_i18n.t.bind(v_i18n);
export const tc = v_i18n.tc.bind(v_i18n);

export default v_i18n;
