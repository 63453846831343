import { v4 as uuid } from 'uuid';

const listenerCallbacks = {};

/**
 * Because of animations, we need to throttle how often we trigger a resize
 * event. Therefore, we let the observer set the callback to the
 * listenerCallbacks object, which we check every 500ms. This means that only
 * the last callback is invoked every 500ms.
 */
setInterval(() => {
  Object.keys(listenerCallbacks).forEach((listenerId) => {
    const listenerCallback = listenerCallbacks[listenerId];
    delete listenerCallbacks[listenerId];
    listenerCallback();
  });
}, 500);

/**
 *
 * @param {(HTMLElement|SVGElement)} target
 * @param {(entry: ResizeObserverEntry) => {}} callback
 */
const onResize = (target, callback) => {
  const listenerId = uuid();
  const resizeObserver = new ResizeObserver((entries) => {
    const entry = entries?.[0];
    if (entry) {
      listenerCallbacks[listenerId] = () => callback(entry);
    }
  });

  resizeObserver.observe(target);
};

export default onResize;
