import laApiInstance from '@/util/laApi';
const laApi = laApiInstance();

const getDefaultState = () => ({
  templates: null,
  countries: [],
  template: null,
});
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getCountriesCount: (state) => state.countries?.length ?? 0,
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setCountries(state, value) {
      state.countries = value;
    },
    setTextTemplates(state, templates) {
      state.templates = templates;
    },
    setTextTemplate(state, template) {
      template.countryMessages.forEach((msg) => {
        msg.isValid = true;
      });
      state.template = template;
    },
  },
  actions: {
    async getCountries({ commit, getters }) {
      let countriesCount = getters.getCountriesCount;
      if (countriesCount <= 0) {
        const res = await laApi.get(`country`);
        commit('setCountries', res.data);
        return res.data;
      }
    },
    getTextTemplates({ commit }) {
      return laApi
        .get('texttemplate/')
        .then((response) => commit('setTextTemplates', response.data));
    },
    getTextTemplate({ commit }, templateId) {
      return laApi
        .get('texttemplate/' + templateId)
        .then((response) => commit('setTextTemplate', response.data));
    },
    async createTextTemplate({ dispatch }, template) {
      const res = await laApi.post('texttemplate/', template);
      await dispatch('getTextTemplates');
      return res.data;
    },
    async updateTextTemplate({ dispatch }, template) {
      await laApi.put(`texttemplate/${template.id}`, template);
      await dispatch('getTextTemplates');
    },
    async deleteTextTemplate({ dispatch }, template) {
      await laApi.delete(`texttemplate/${template.id}`);
      await dispatch('getTextTemplates');
    },
    reset({ commit }) {
      commit('resetState');
    },
  },
};
