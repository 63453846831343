<template>
  <v-menu right bottom>
    <template #activator="{ on }">
      <v-btn :color="color" icon aria-label="Select language" v-on="on"
        ><v-icon>fal fa-globe-europe</v-icon></v-btn
      >
    </template>
    <v-list>
      <v-list-item-group v-model="lang" color="primary">
        <v-list-item v-for="(language, i) in languageArray" :key="`lang${i}`" :value="language">
          <v-list-item-content>
            {{ language.name }}
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import i18n, { languages } from '@/plugins/i18n';
export default {
  name: 'LocaleSwitcher',

  props: {
    color: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      languageArray: languages,
    };
  },
  computed: {
    lang: {
      get: function () {
        i18n.locale = this.$store.state.user.locale;
        return this.$store.state.user.locale;
      },
      set: function (newVal) {
        this.$store.dispatch('user/changeLocale', newVal.value);
      },
    },
  },
  methods: {
    switchLang(locale) {
      this.$store.dispatch('user/changeLocale', locale);
    },
  },
};
</script>

<style></style>
