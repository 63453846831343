export const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    timeWithSeconds: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    tableDateTime: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    tableDateTimeWithoutYear: {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    longDate: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    },
    longDateWithoutYear: {
      month: 'short',
      day: 'numeric',
    },
    shortDate: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    },
  },
  no: {
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    long: {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    time: {
      hour: 'numeric',
      minute: 'numeric',
    },
    timeWithSeconds: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    tableDateTime: {
      day: '2-digit',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    },
    tableDateTimeWithoutYear: {
      month: 'short',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    },
    longDate: {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    },
    longDateWithoutYear: {
      month: 'short',
      day: '2-digit',
    },
    shortDate: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  },
};
