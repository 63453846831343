import laApiInstance from '@/util/laApi';
const laApi = laApiInstance();

const getDefaultState = () => ({
  status: null,
  info: null,
  alertId: null,
});

export default {
  namespaced: true,

  state: getDefaultState(),
  getters: {
    getTotalDeliveredSmsPercent(state) {
      if (!state.status || state.status.deliveryStatus.total === 0) {
        return 0;
      } else {
        return Math.round(
          (state.status.deliveryStatus.deliveredSuccessfully / state.status.deliveryStatus.total) *
            100,
        );
      }
    },
    getTotalFailedSmsPercent(state) {
      if (!state.status || state.status.deliveryStatus.total === 0) {
        return 0;
      } else {
        return Math.round(
          (state.status.deliveryStatus.deliveryFailed / state.status.deliveryStatus.total) * 100,
        );
      }
    },
    getTotalUnknownSmsPercent(state) {
      if (!state.status || state.status.deliveryStatus.total === 0) {
        return 0;
      } else {
        return Math.round(
          (state.status.deliveryStatus.deliveryUnknown / state.status.deliveryStatus.total) * 100,
        );
      }
    },
    getTotalProcessedPercent(state) {
      if (!state.status?.deliveryStatus) return 0;
      if (state.status.deliveryStatus.total === 0) {
        return 0;
      } else {
        let processed = state.status.deliveryStatus.total - state.status.deliveryStatus.processing;
        return Math.round((processed / state.status.deliveryStatus.total) * 100);
      }
    },
    getTotalProcessingPercent(state) {
      if (!state.status || state.status.deliveryStatus.total === 0) {
        return 0;
      } else {
        return Math.round(
          (state.status.deliveryStatus.processing / state.status.deliveryStatus.total) * 100,
        );
      }
    },
  },
  mutations: {
    resetState(state) {
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getDefaultState());
    },
    setInfo(state, info) {
      state.info = info;
    },
    setStatus(state, status) {
      state.status = status;
    },
  },
  actions: {
    getStatus({ commit }, alertId) {
      return laApi.get('alerts/' + alertId + '/status').then((response) => {
        if (alertId == this.alertId) {
          commit('setStatus', response.data);
        }
      });
    },

    getInfo({ commit }, alertId) {
      this.alertId = alertId;
      return laApi.get('alerts/' + alertId).then((response) => {
        if (alertId == this.alertId) {
          commit('setInfo', response.data);
        }
      });
    },
    cancelAlert({ commit }, alertId) {
      return laApi
        .post('alerts/' + alertId + '/cancelled')
        .then((response) => commit('setInfo', response.data));
    },
    reset({ commit }) {
      // eslint-disable-next-line no-console
      commit('resetState');
    },
  },
};
