<template>
  <div>
    <v-app-bar clipped-left app color="primary" dark src="../../assets/images/bgr.jpg">
      <v-app-bar-nav-icon aria-label="Menu" @click.stop="drawer = !drawer" />
      <router-link to="/" class="logo-home-link">
        <v-toolbar-title class="mr-4">
          {{ $t('locationAlert') }}
        </v-toolbar-title>
      </router-link>

      <v-chip v-if="environment === 'local'" color="simulation" text-color="#422400" label
        ><v-icon left small> fal fa-code-branch </v-icon> Local</v-chip
      >
      <v-chip v-else-if="environment === 'demo'" color="simulation" text-color="#422400" label
        ><v-icon left small> fal fa-code-branch </v-icon> Demo</v-chip
      >
      <v-chip v-else-if="environment === 'beta'" color="yellow" text-color="#5c5516" label
        ><v-icon left small> fal fa-code-branch </v-icon> Beta</v-chip
      >
      <v-spacer />
      <LocaleSwitcher />
      <v-btn rounded outlined @click="logout"
        >{{ $t('logout') }}
        <v-icon right dark> fal fa-sign-out </v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app clipped mobile-breakpoint="1700" color="#383837" dark>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ user.profile.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.profile.customer.name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list nav>
        <template v-for="(navItem, index) in navItems">
          <v-list-item
            :key="index"
            link
            :to="{ name: navItem.linkToName }"
            exact
            @click="drawer = !drawer"
          >
            <v-list-item-icon>
              <v-icon>fal fa-fw fa-{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(navItem.title) }}</v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item v-if="user.loggedInFromV24" link exact :href="v24Url">
          <v-list-item-icon>
            <v-icon>fal fa-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('loginV24Sso.backToV24') }}</v-list-item-title>
        </v-list-item>
        <v-list-item link exact @click="logout">
          <v-list-item-icon>
            <v-icon>fal fa-sign-out</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
      <p class="text-center ma-0 pa-4 grey--text text--darken-1 caption">
        {{ $t('version') }}: {{ buildNumber }}
      </p>
    </v-navigation-drawer>
    <confirm ref="confirm" />
  </div>
</template>

<script>
import LocaleSwitcher from '@/components/LocaleSwitcher.vue';
import Confirm from '@/components/dialog/Confirm.vue';
import appSettings from '@/appSettings';

export default {
  name: 'MainNavigation',
  components: {
    LocaleSwitcher,
    Confirm,
  },
  data: () => ({
    drawer: null,
    navItemIndex: null,
    navItems: [],
    buildNumber: appSettings.VUE_APP_BUILD_TIMESTAMP,
    v24Url: appSettings.v24Url,
    environment: appSettings.environment,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    userIsAdmin() {
      if (this.$store.state.user) {
        return this.$store.state.user.profile.isAdmin;
      }
      return false;
    },
    apisAvailable() {
      return this.$store.state.user.apiStatus ? true : false;
    },
  },
  watch: {
    apisAvailable() {
      if (this.apisAvailable == true) {
        this.setNavItems();
      } else {
        this.navItems = [];
      }
    },
  },
  mounted() {
    if (this.apisAvailable) {
      this.setNavItems();
    }
  },
  methods: {
    async logout() {
      if (
        await this.$refs.confirm.open(
          this.$t('logout'),
          this.$t('logoutMsg'),
          this.$t('logout'),
          this.$t('cancel'),
        )
      ) {
        this.$store.dispatch('user/logOut');
        this.$router.push({ name: 'Login' });
      }
    },
    setNavItems() {
      this.navItems = [
        //   {
        //   linkToName: 'MapTester',
        //   title: 'mapTester',
        //   icon: 'map-marked-alt'
        // },
        {
          linkToName: 'Dashboard',
          title: 'dashboardMenuItem',
          icon: 'tachometer-fast',
        },
        {
          linkToName: 'NewAlert',
          title: 'newAlert',
          icon: 'bullhorn',
        },
        {
          linkToName: 'AlertLog',
          title: 'alertLogMenuItem',
          icon: 'clipboard-check',
        },
        {
          linkToName: 'Areas',
          title: 'areasMenuItem',
          icon: 'draw-polygon',
        },
        {
          linkToName: 'SubscriberLists',
          title: 'subscriberLists',
          icon: 'address-book',
        },
        {
          linkToName: 'TextTemplates',
          title: 'textTemplatesMenuItem',
          icon: 'file-alt',
        },
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-home-link {
  width: auto;
  height: 100%;
  padding: 14px 0 12px 0;
  color: white;
  text-decoration: none;
}
</style>
