import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
library.add(faBars);

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#457B9D',
        secondary: '#457B9D',
        third: colors.red.darken2,
        simulation: colors.orange.darken1,
        accent: colors.shades.black,
        error: '#ce1717',
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    // iconfont:'fa',
    values: {
      // set menu to light (default is solid)
      menu: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fal', 'bars'],
        },
      },
      // reusable custom icon
      vuejs: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fab'],
        },
      },
    },
  },
});
