<template>
  <v-app id="app">
    <MainNavigation v-if="isAuthenticated && !hideNavigation" />
    <v-main>
      <router-view class="full-height" />
    </v-main>
  </v-app>
</template>

<script>
import MainNavigation from '@/components/navigation/MainNavigation.vue';
import i18n from './plugins/i18n';

export default {
  name: 'App',
  components: {
    MainNavigation,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    isAuthenticated() {
      return this.$store.state.user.isAuthenticated;
    },

    hideNavigation() {
      return this.$route.meta.hideNavigation;
    },
  },
  created() {
    if (this.$store.state.user.locale) {
      i18n.locale = this.$store.state.user.locale;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logOut');
      // this.$router.push({ name:'Login' });
    },
  },
};
</script>
<style lang="scss">
html {
  overflow-y: auto !important;
}
.full-height {
  height: 100%;
}
.logo-home-link {
  width: auto;
  height: 100%;
  padding: 16px 0 12px 0;
}
.v-main__wrap {
  // left: 36px;
  background-color: #eae5db// #ececec;
}
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.8) !important;
}
.pointer {
  cursor: pointer;
}
.default-cursor {
  cursor: default;
}
.empty-state-icon {
  color: #b2bfc7; //#b0d8f1;
  font-size: 5em;
  margin: auto;
}
.capitalize-first-letter {
  text-transform: capitalize;
}
.v-card__title {
  word-break: break-word;
}
.text-word-break {
  word-break: break-all;
}
.selectable {
  cursor: pointer;
  padding: 8px 0;
  text-align: center;
}
.no-wrap {
  white-space: nowrap;
}
</style>
