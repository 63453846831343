import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/plugins/vuetify';
import router from '@/util/router';
import store from '@/store';
import '@fortawesome/fontawesome-pro/css/all.css';
import i18n from '@/plugins/i18n';
import { ProgressPlugin } from 'bootstrap-vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import vueAwesomeCountdown from 'vue-awesome-countdown';

Vue.config.productionTip = false;
Vue.use(ProgressPlugin);
Vue.use(vueNumeralFilterInstaller, { locale: 'no' });
Vue.use(vueAwesomeCountdown, 'vac');
new Vue({
  vuetify,
  router,
  store,
  i18n,
  ProgressPlugin,
  render: (h) => h(App),
}).$mount('#app');
