<template>
  <div class="d-flex justify-center">
    <div>
      <Map
        ref="map"
        :center="center"
        width="100vw"
        height="calc(100vh - 64px)"
        map-type="streets"
        :markers="markers"
        :draw-tools="drawTools"
        :search-control="true"
        :limits="limits"
        @ready="setLimitsBergen()"
      />
      <div class="test-button-wrapper">
        <v-btn @click="setLimitsBergen">Set limits to Bergen</v-btn>
        <v-btn @click="setLimitsOslo">Set limits to Oslo</v-btn>
        <v-btn @click="setLimitsNorway">Set limits to Norway</v-btn>
        <v-btn @click="limits = null">Remove limits</v-btn>
        <v-btn @click="drawTools = !drawTools">
          <template v-if="drawTools">Disable draw tools</template>
          <template v-else>Enable draw tools</template>
        </v-btn>
        <v-btn @click="addMarker">
          Add random marker in area
          <v-img width="20px" height="20px" src="@/assets/images/marker-icon.png" />
        </v-btn>
        <v-btn @click="markers = []">
          Remove all
          <v-img width="20px" height="20px" src="@/assets/images/marker-icon.png" />
        </v-btn>
        <v-btn @click="goTo({ lat: 60.35, lng: 5.25 })"> Go to Bergen </v-btn>
        <v-btn @click="goTo({ lat: 59.9, lng: 10.75 })"> Go to Oslo </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '@/components/Map';

const getRandomInRange = (from, to, fixed) =>
  (Math.random() * (to - from) + from).toFixed(fixed) * 1;

export default {
  components: {
    Map,
  },

  data: () => ({
    markers: [],
    center: {
      lat: 0,
      lng: 0,
    },
    res: null,
    drawTools: true,
    limits: null,
  }),

  mounted() {
    this.goTo({ lat: 60.35, lng: 5.25 });
  },
  methods: {
    randomLat() {
      const int = 0.15;
      return getRandomInRange(this.center.lat - int, this.center.lat + int, 3);
    },
    randomLng() {
      const int = 0.25;
      return getRandomInRange(this.center.lng - int, this.center.lng + int, 3);
    },
    addMarker() {
      const marker = {
        latLng: {
          lat: this.randomLat(),
          lng: this.randomLng(),
        },
        text: 1,
        id: getRandomInRange(0, 100000),
      };
      this.markers.push(marker);
    },
    goTo(latLng) {
      this.center = latLng;
    },

    setLimitsBergen() {
      this.limits = [
        [
          { lat: 60.403941003301846, lng: 5.233979455859967 },
          { lat: 60.40993829189886, lng: 5.270150974435905 },
          { lat: 60.4928900893054, lng: 5.240342517044883 },
          { lat: 60.519030392373665, lng: 5.273135248571635 },
          { lat: 60.535725001505185, lng: 5.306372990204712 },
          { lat: 60.499640323125, lng: 5.4041367676109076 },
          { lat: 60.42670284625493, lng: 5.511853862553836 },
          { lat: 60.354715120304, lng: 5.512828845530749 },
          { lat: 60.32118620490158, lng: 5.538167674094439 },
          { lat: 60.27080361833585, lng: 5.448848605155946 },
          { lat: 60.27194396061378, lng: 5.394324157387019 },
          { lat: 60.242881041635485, lng: 5.287234615534545 },
          { lat: 60.25330736233453, lng: 5.235156761482358 },
          { lat: 60.23433545140247, lng: 5.238036699593068 },
          { lat: 60.277156898976344, lng: 5.189044140279294 },
          { lat: 60.324163791888864, lng: 5.204538423568011 },
          { lat: 60.341775671028245, lng: 5.178614538162948 },
          { lat: 60.38318867326956, lng: 5.165691319853068 },
        ],
        [
          { lat: 60.28496767000872, lng: 5.162690458705815 },
          { lat: 60.29651786308723, lng: 5.174343219382079 },
          { lat: 60.30373466178129, lng: 5.15880620514702 },
          { lat: 60.29748019499319, lng: 5.143269190912001 },
          { lat: 60.286411667306425, lng: 5.148124507860455 },
        ],
      ];
      // this.$refs.map.flyToLimits();
    },

    setLimitsOslo() {
      this.limits = [
        [
          { lat: 60.11335456164668, lng: 10.762189161032442 },
          { lat: 60.124985457937626, lng: 10.736096631735563 },
          { lat: 60.11882843647586, lng: 10.707257520407438 },
          { lat: 60.131141327833674, lng: 10.675671827048063 },
          { lat: 60.13250914255075, lng: 10.611127149313692 },
          { lat: 60.11814425188954, lng: 10.578168164938688 },
          { lat: 60.075011949525205, lng: 10.600140821188692 },
          { lat: 60.07638209681341, lng: 10.579541455954313 },
          { lat: 60.016728049324136, lng: 10.490277539938688 },
          { lat: 59.998192802507745, lng: 10.512250196188688 },
          { lat: 60.00162603944407, lng: 10.541089307516813 },
          { lat: 59.97896008988735, lng: 10.597394239157442 },
          { lat: 59.98170830458973, lng: 10.613873731344942 },
          { lat: 59.93908533275626, lng: 10.646832715719938 },
          { lat: 59.92257139341334, lng: 10.633099805563688 },
          { lat: 59.91086904500458, lng: 10.656445752829313 },
          { lat: 59.88538496241774, lng: 10.660565625876188 },
          { lat: 59.87780484256403, lng: 10.693524610251188 },
          { lat: 59.87849401580432, lng: 10.736096631735563 },
          { lat: 59.838498343961426, lng: 10.745709668844942 },
          { lat: 59.82400529336953, lng: 10.774548780173063 },
          { lat: 59.82331499078265, lng: 10.810254346579313 },
          { lat: 59.816411178195615, lng: 10.821240674704313 },
          { lat: 59.816411178195615, lng: 10.845959912985563 },
          { lat: 59.80812471476919, lng: 10.852826368063688 },
          { lat: 59.81295873546415, lng: 10.881665479391817 },
          { lat: 59.8260761153119, lng: 10.900891553610563 },
          { lat: 59.83159767785849, lng: 10.935223829001188 },
          { lat: 59.87209398531225, lng: 10.903244353830816 },
          { lat: 59.928076953332145, lng: 10.924237500876188 },
          { lat: 59.948026951037924, lng: 10.951703321188688 },
          { lat: 59.98445629116176, lng: 10.925610791891813 },
          { lat: 59.979647164950855, lng: 10.899518262594942 },
          { lat: 59.99407244781923, lng: 10.866559278219938 },
          { lat: 60.00299923445022, lng: 10.845959912985563 },
          { lat: 59.996819407960665, lng: 10.836346875876192 },
          { lat: 60.02496260251419, lng: 10.829480420798063 },
          { lat: 60.0153554243284, lng: 10.814374219626188 },
          { lat: 60.04005395570881, lng: 10.818494092673063 },
          { lat: 60.06678987031126, lng: 10.818494092673063 },
          { lat: 60.06747512185959, lng: 10.781415235251188 },
          { lat: 60.10171955571052, lng: 10.753949414938692 },
        ],
      ];
      // this.$refs.map.flyToLimits();
    },
    setLimitsNorway() {
      this.limits = [
        [
          { lat: 61.13336939143471, lng: 3.3734739868103256 },
          { lat: 57.410287261746525, lng: 6.581481672987328 },
          { lat: 58.871377557698665, lng: 12.03070020841128 },
          { lat: 67.58468236314616, lng: 17.392028122296143 },
          { lat: 70.28222178260498, lng: 31.41058225778195 },
          { lat: 71.80874998869851, lng: 25.785582479279814 },
          { lat: 68.61709272470446, lng: 12.558043937645857 },
        ],
      ];
      // this.$refs.map.flyToLimits();
    },
  },
};
</script>

<style lang="scss" scoped>
.test-button-wrapper {
  position: fixed;
  z-index: 1;
  left: 10px;
  bottom: 10px;
  right: 50%;

  > * {
    margin: 5px;
  }
}
</style>
